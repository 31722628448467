<template>
    <div class="comp-select">
        <select v-model="comp.idx_category" @change="comp.update" :disabled="comp.disabled">
            <option value="0">전체</option>
            <option :value="irow.idx" v-for="(irow, i) in comp.list" :key="i">{{ irow.title }}</option>
        </select>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotBookCategory',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'update:modelValue', 'update:disabled' ],
    setup(props, { emit }) {
        const toast = useToast()

        const comp = reactive({
            disabled     : false,
            list         : [],
            idx_category : 0,

            doSearch : () => {
                let params = {};
                axios.get("/rest/component/getBookCategoryList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            update: () => {
                emit('update:modelValue', comp.idx_category);
            }

        });

        watch(() => props.disabled,(nVal, oVal) => {
            if( nVal != oVal ){
                comp.disabled = nVal;
                comp.idx_category = 0;
                comp.update();
            }
        });

        onMounted(() => {
            // Mounted
            comp.idx_category = props.modelValue;

            if( props.disabled == true ){
                comp.disabled = true;
            }

            comp.doSearch();
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
.comp-select {
    display: inline-block;
    select {
        width: 100%;
        margin-right: 10px;
    }
}
</style>