<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="입고 등록"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 구분</th>
                                <td>
                                    <label><input type="radio" v-model="add.btype" value="외부"><span class="ml-5 mr-20"> 외부교재</span></label>
                                    <label><input type="radio" v-model="add.btype" value="내부"><span class="ml-5 mr-20"> 내부교재</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 출판사</th>
                                <td>
                                    <input type="text" v-model.trim="add.publisher" class="w-400px">
                                </td>
                            </tr>
                            <tr>
                                <th>구입거래처</th>
                                <td>
                                    <select v-model="add.book_seller" class="w-400px">
                                        <option value="">선택</option>
                                        <option v-for="(irow, i) in comp.sellerList" :key="i" :value="irow.code">{{ irow.name }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 카테고리</th>
                                <td>
                                    <carrot-book-category :disabled="(add.btype=='외부'?true:false)" v-model="add.idx_book_category" class="w-400px"></carrot-book-category>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 교재명</th>
                                <td>
                                    <!-- <div>
                                        <input type="text" class="w-400px" placeholder="기존 교재는 검색 후 선택해주세요.">
                                    </div> -->
                                    <carrot-book-title v-model:bookname.sync="add.title" v-model="add.idx_book" class="w-400px"></carrot-book-title>
                                    <div class="mt-10">
                                        <input type="text" v-model.trim="add.title" class="w-400px">
                                        <span class="color-1 ml-10">신규 교재일 경우 교재명을 정확하게 입력해주세요.</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 수량</th>
                                <td>
                                    <input type="text" v-model.number="add.qty" class="w-100px txt-right" maxlength="10">
                                    <span class="ml-10">권</span>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 금액</th>
                                <td>
                                    <input type="text" v-model.number="add.price" class="w-100px txt-right" maxlength="20">
                                    <span class="ml-10">원</span>
                                    <span class="color-1 ml-10">* 1권당 정가 금액을 입력해주세요.</span>
                                </td>
                            </tr>
                            <tr>
                                <th>비고<br>(버전정보, 기타내용 등)</th>
                                <td>
                                    <textarea v-model.trim="add.memo" class="w-100per h-100px" maxlength="500"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotBookCategory from "@/components/common/CarrotBookCategory.vue"
import CarrotBookTitle from "@/components/common/CarrotBookTitle.vue"
import Swal from 'sweetalert2'



export default {
    layout:"ResourceManagement",
    components: {
        CarrotBookCategory,
        CarrotBookTitle
    },

    setup() {
        const router = useRouter();
        const toast  = useToast()

        const add = reactive({
            idx_book          : 0,
            btype             : "외부",
            publisher         : "",
            book_seller       : "",
            idx_book_category : "0",
            title             : "",
            qty               : 0,
            price             : 0,
            memo              : "",

            doCancel: () => {
                router.go(-1);
                // router.push({ name:"BusinessManagement-eLearningList" });
            },

            doSubmit : () => {
                let params = {
                    btype             : add.btype,
                    publisher         : add.publisher,
                    book_seller       : add.book_seller,
                    idx_book_category : add.idx_book_category,
                    title             : add.title,
                    qty               : add.qty,
                    price             : add.price,
                    memo              : add.memo,
                };

                if( !params.btype ){
                    toast.error("구분을 선택하세요.");
                    return;
                }
                if( !params.publisher ){
                    toast.error("출판사를 입력하세요.");
                    return;
                }
                if( params.btype == '내부' && !params.idx_book_category ){
                    toast.error("카테고리를 선택하세요.");
                    return;
                }
                if( !params.title ){
                    toast.error("교재명을 입력하세요.");
                    return;
                }
                if( !params.qty ){
                    toast.error("수량을 입력하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/addBook", params).then((res) => {
                    if( res.data.err == 0 ){
                        sessionStorage.setItem('TextbookMaintenanceList', JSON.stringify({
                            page : 1
                        }));
                        Swal.fire({
                            title: '입고 등록',
                            text : '등록하였습니다.'
                        }).then(() => {
                            router.push({
                                name : 'ResourceManagement-TextbookMaintenanceList'
                            })
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        const comp = reactive({
            categoryList: [],
            sellerList  : [],

            getBookCategory: () => {
                let params = {};
                axios.get('/rest/component/getBookCategoryList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.categoryList = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            getBookSeller: () => {
                let params = {};
                axios.get('/rest/component/getBookSellerList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.sellerList = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        onMounted(() => {
            // Mounted

            comp.getBookCategory();
            comp.getBookSeller();

        });

        return {comp, add};
    }
}
</script>

<style lang="scss" scoped>
</style>